import { useCallback } from 'react';
import { useQuery } from '@apollo/client';

import { OutstandingAgreementType } from '__generated__/GQL';
import asBentoModule from 'common/bento/hoc/asBentoModule';
import withStateMachine from 'common/bento/hoc/withStateMachine/withStateMachine';
import assertNever from 'common/bento/lib/assertNever';
import stringifyState from 'common/bento/lib/stringifyState';
import LoaderPage from 'common/bento/pages/LoaderPage';
import { BentoModuleDoneStatus } from 'common/bento/types/BentoModule';
import useCompanyContext from 'common/hooks/useCompanyContext';
import SuspenseQuery from 'components/SuspenseQuery';
import OBLoaderPage from 'features/Onboarding/components/OBLoaderPage/OBLoaderPage';
import logger from 'helpers/logger';

import { GetAgreementDataDocument } from './graphql/queries/getAgreementData.gql';
import machineConfig, { model, State } from './machine';
import AgreementPage from './pages/AgreementPage';
import DocumentSignature from './pages/DocumentSignature';
import ReviewPage from './pages/ReviewPage/ReviewPage';

const AgreementComponent = withStateMachine<typeof model>(
  ({ machine, moduleData }) => {
    const [state, send] = machine;

    const stringifiedState = stringifyState<State>(state);
    const onPrev = useCallback(() => send(model.events.PREV()), [send]);

    switch (stringifiedState) {
      case State.DOCUMENT: {
        const [agreement] = moduleData.viewer.outstandingAgreements;

        if (!agreement) {
          throw Error(`No agreement in step ${State.DOCUMENT}`);
        }

        return (
          <AgreementPage
            agreement={agreement}
            isLoading={state.matches(State.AGREEMENT_SEND)}
            onNext={() => send(model.events.NEXT())}
            onPrev={onPrev}
          />
        );
      }

      case State.DOCUMENT_SIGNATURE: {
        const [agreement] = moduleData.viewer.outstandingAgreements;

        if (!agreement) {
          throw Error(`No agreement in step ${State.DOCUMENT_SIGNATURE}`);
        }

        return (
          <DocumentSignature
            agreement={agreement}
            companyProfileId={moduleData.viewer.company.companyProfileId}
            onNext={() => send(model.events.NEXT())}
            onPrev={onPrev}
          />
        );
      }

      case State.REVIEW: {
        const { agreementType } = moduleData;

        if (agreementType !== OutstandingAgreementType.Cgu) {
          throw Error(
            `${State.REVIEW} state doesn't handle agreement of type ${agreementType}`,
          );
        }

        return (
          <ReviewPage
            agreementType={agreementType}
            onNext={() => send(model.events.NEXT())}
            onPrev={onPrev}
          />
        );
      }

      case State.INIT:
      case State.DOCUMENT_INIT:
      case State.AGREEMENT_SEND:
      case State.ASSIGNMENT_SEND:
      case State.COMPLETE_AGREEMENT:
      case State.AGREEMENT_SIGNED:
      case State.ABORT:
      case State.DONE:
        return <LoaderPage onPrev={() => null} />;

      default:
        return assertNever(stringifiedState);
    }
  },

  {
    devTools: true,
    machineConfig,
  },
);

interface AgreementProps {
  type: OutstandingAgreementType;
}

const Agreement = asBentoModule<AgreementProps>(({ onDone, type }) => {
  const { companyProfileId } = useCompanyContext();

  if (!companyProfileId) {
    logger.error('No company context in agreement module');

    onDone({ status: BentoModuleDoneStatus.ABORT });

    return null;
  }

  const query = useQuery(GetAgreementDataDocument, {
    fetchPolicy:
      type === OutstandingAgreementType.NotaryFourez
        ? 'network-only'
        : 'cache-first',
    variables: {
      agreementType: type,
      companyProfileId,
    },
  });

  return (
    <SuspenseQuery loader={<OBLoaderPage onPrev={() => null} />} query={query}>
      {(moduleData) => (
        <AgreementComponent
          moduleData={{ ...moduleData, agreementType: type }}
          onDone={onDone}
        />
      )}
    </SuspenseQuery>
  );
});

export default Agreement;
