import { type FC } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Grid, Image } from '@chakra-ui/react';
import { Typography, XStack, YStack } from '@shinetools/sunshine-universal';

import { SubscriptionPlanFrequency } from '__generated__/GQL';
import SunshineCard from 'components/_core/SunshineCard';
import SquircleIcon from 'components/_core/SunshineSquircleIcon';
import { PLANS_CARD_IMAGES } from 'features/SubscriptionManagement/lib/cardImages';
import { PLANS_METADATA } from 'features/SubscriptionManagement/pages/Plans/plansMetadata';
import { type SubscriptionManagementPlansDetailFragment } from 'features/SubscriptionManagement/subscriptionManagementPlansDetail.gql';

type PlanRecapProps = {
  targetPricingPlan: SubscriptionManagementPlansDetailFragment;
};

export const PlanRecap: FC<PlanRecapProps> = ({ targetPricingPlan }) => (
  <SunshineCard.Group maxWidth={540} overflow="hidden">
    <SunshineCard
      backgroundColor="blue.300"
      borderRadius="radius-0"
      paddingX="space-32"
      paddingY="space-24!"
    >
      <Typography.Text bold variant="primary">
        <FormattedMessage id="subscription.plan_details.selected_offer_summary" />
      </Typography.Text>
    </SunshineCard>

    <SunshineCard
      backgroundColor="grey.100"
      borderRadius="radius-0"
      overflow="hidden"
      padding="0!"
    >
      <Grid gridTemplateColumns="1fr auto">
        <YStack paddingLeft="$space.32" paddingVertical="$space.32">
          <Typography.Header marginBottom="$space.8" size="large">
            <FormattedMessage
              id="subscription.plan_details.selected_offer_title"
              values={{
                planName: targetPricingPlan.brandName,
              }}
            />
          </Typography.Header>

          <Typography.Text bold variant="primary">
            <FormattedMessage
              id={
                targetPricingPlan.billingFrequency ===
                SubscriptionPlanFrequency.Yearly
                  ? 'subscription.plan_details.selected_offer_pricing.yearly'
                  : 'subscription.plan_details.selected_offer_pricing'
              }
              values={{
                price: (
                  <FormattedNumber
                    currency="EUR"
                    minimumFractionDigits={
                      targetPricingPlan.priceWithoutVAT % 100 ? 2 : 0
                    }
                    style="currency"
                    value={targetPricingPlan.priceWithoutVAT / 100}
                  />
                ),
                trialPeriodLength: 1,
              }}
            />
          </Typography.Text>
        </YStack>

        <Image
          alignSelf="end"
          height={105}
          marginRight="space-8"
          objectFit="cover"
          objectPosition="top"
          src={PLANS_CARD_IMAGES[targetPricingPlan.providerPlanId]}
          width={215}
        />
      </Grid>
    </SunshineCard>

    <SunshineCard paddingX="space-32" paddingY="space-32!">
      <Typography.Text variant="secondary">
        <FormattedMessage
          id="subscription.plan_details.summary.subtitle"
          values={{
            planName: targetPricingPlan.brandName,
          }}
        />
      </Typography.Text>

      <YStack gap="$space.32" marginTop="$space.32">
        {PLANS_METADATA[targetPricingPlan.providerPlanId].summary.map(
          ({ description, icon }) => {
            const descriptionNode = description(targetPricingPlan);

            return (
              <XStack alignItems="center" gap="$space.16" key={icon}>
                <SquircleIcon name={icon} />
                <Typography.Text bold>{descriptionNode}</Typography.Text>
              </XStack>
            );
          },
        )}
      </YStack>
    </SunshineCard>
  </SunshineCard.Group>
);
