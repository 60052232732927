import { type IntlConfig } from 'react-intl/src/types';
import { List, ListItem } from '@chakra-ui/react';
import { Typography } from '@shinetools/sunshine-universal';

import { webAppLang, webAppLocales } from '../i18n';

/**
 * Main React IntlProvider config, to share same config within the web-app and storybook
 */
export const intlConfig: IntlConfig = {
  defaultLocale: 'fr',
  defaultRichTextElements: {
    bold: (chunks) => (
      <Typography.Text bold tag="span">
        {chunks}
      </Typography.Text>
    ),
    list: (chunks) => <List spacing="space-4">{chunks}</List>,
    list_item: (chunks) => (
      <ListItem lineHeight="base" listStyleType="disc" marginLeft="1em">
        {chunks}
      </ListItem>
    ),
    nbsp: '\u00A0',
  },
  locale: webAppLang,
  messages: webAppLocales[webAppLang],
};
