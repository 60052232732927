
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "BankTransferRecipientPayloadWithSca": [
      "BankTransferRecipientPayload",
      "StrongAuthenticationRequired"
    ],
    "CreateInternationalTransferQuoteResponse": [
      "CreateInternationalTransferQuoteResponseError",
      "CreateInternationalTransferQuoteResponseSuccess"
    ],
    "CreateInternationalTransferRecipientResponse": [
      "InternationalTransferProviderErrorResponse",
      "InternationalTransferRecipient"
    ],
    "CreateInternationalTransferResponse": [
      "InternationalTransferPayout",
      "InternationalTransferProviderErrorResponse"
    ],
    "CreditCardInterface": [
      "CreditCard",
      "CreditCardPendingOrder"
    ],
    "EnrollDeviceOutput": [
      "Device",
      "StrongAuthenticationRequired"
    ],
    "FeatureFilter": [
      "RoleFilter",
      "UserFilter"
    ],
    "PaymentCardInterface": [
      "PaymentCard",
      "PaymentCardPendingOrder"
    ],
    "PhysicalPaymentCardPinPayloadWithSca": [
      "PhysicalPaymentCardPin",
      "StrongAuthenticationRequired"
    ],
    "TeamMembersRequest": [
      "BankTransferRequest",
      "ExpenseClaimRequest",
      "PhysicalPaymentCardOrderRequest",
      "VirtualBudgetCardOrderRequest",
      "VirtualPaymentCardOrderRequest"
    ],
    "UnderReviewWidget": [
      "KycUnderReviewWidget",
      "OnboardingTimelineWidget"
    ]
  }
};
      export default result;
    