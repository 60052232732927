import { type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { chakra } from '@chakra-ui/react';
import { Icon, Typography, YStack } from '@shinetools/sunshine-universal';

import { INSURANCE_2024_NOTICE_URL } from 'common/insurance';
import * as Form from 'components/_core/form';

type InsuranceTermsFormProps = {
  brandName: string;
  isDisabled?: boolean;
};

export const InsuranceTermsForm: FC<InsuranceTermsFormProps> = ({
  brandName,
  isDisabled,
}) => {
  const { control } = useFormContext();

  return (
    <YStack>
      <YStack gap="$space.8">
        <Typography.Text bold>
          <FormattedMessage id="subscription.plan_details.insurance_terms.title" />
        </Typography.Text>

        <Typography.Text
          marginBottom="$space.16"
          size="small"
          variant="secondary"
        >
          <FormattedMessage
            id="subscription.plan_details.insurance_terms.description"
            values={{
              more_info_insurances_link: (content) => (
                <chakra.a
                  _hover={{
                    color: 'blue.700',
                  }}
                  alignItems="center"
                  color="blue.600"
                  display="inline-flex"
                  gap="space-8"
                  href="https://www.shine.fr/extras-assurances/"
                  target="_blank"
                  textDecoration="underline"
                >
                  <span>{content}</span>
                  <Icon icon="link" size="small" />
                </chakra.a>
              ),
              planName: brandName,
            }}
          />
        </Typography.Text>
      </YStack>

      <Controller
        control={control}
        name="hasAgreedToInsuranceTerms"
        render={({ field, fieldState }) => (
          <Form.Field error={fieldState.error?.message}>
            <Form.Checkbox.Group
              checkboxPosition="left"
              isDisabled={isDisabled}
              variant="outline"
              width="full"
            >
              <Form.Checkbox
                borderColor="grey.300"
                isChecked={field.value}
                onChange={(event) => {
                  field.onChange(event.target.checked);
                }}
                padding="space-16"
              >
                <Typography.Text size="small">
                  <FormattedMessage
                    id="subscription.plan_details.insurance_terms.agreement"
                    values={{
                      insurance_terms_pdf: (content) => (
                        <chakra.a
                          _hover={{
                            color: 'blue.700',
                          }}
                          color="blue.600"
                          display="inline"
                          href={INSURANCE_2024_NOTICE_URL}
                          target="_blank"
                          textDecoration="underline"
                        >
                          {content}
                        </chakra.a>
                      ),
                    }}
                  />
                </Typography.Text>
              </Form.Checkbox>
            </Form.Checkbox.Group>
          </Form.Field>
        )}
      />
    </YStack>
  );
};
