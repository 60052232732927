import {
  OutstandingAgreementStatus,
  OutstandingAgreementType,
} from '__generated__/GQL';
import { type MachineGuards } from 'common/bento/types/MachineConfig';

import getIsAgreementCompleted from '../libs/getIsAgreementCompleted';
import { type Model } from './model';

export enum Guard {
  HasSignedAgreement = 'hasSignedAgreement',
  IsDocumentAssigned = 'isDocumentAssigned',
  IsSignatureRequired = 'isSignatureRequired',
  IsCguAgreement = 'IsCguAgreement',
}

const guards: MachineGuards<Model, Guard> = {
  hasSignedAgreement: ({ getModuleData }) => {
    const { agreementType, viewer } = getModuleData();

    return getIsAgreementCompleted({
      agreementType,
      outstandingAgreements: viewer.outstandingAgreements,
    });
  },
  IsCguAgreement: ({ getModuleData }) => {
    const { agreementType } = getModuleData();

    return agreementType === OutstandingAgreementType.Cgu;
  },
  isDocumentAssigned: ({ getModuleData }) => {
    const [agreement] = getModuleData().viewer.outstandingAgreements;

    if (!agreement) {
      throw new Error('No agreement available');
    }

    return agreement.status !== OutstandingAgreementStatus.NoAssignedDocument;
  },
  isSignatureRequired: ({ getModuleData }) => {
    const { agreementType } = getModuleData();

    return agreementType === OutstandingAgreementType.NotaryFourez;
  },
};

export default guards;
