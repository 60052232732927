import LocalizedStrings from 'localized-strings';

import { ProviderPlanId } from '__generated__/GQL';

const locales = new LocalizedStrings({
  en: {
    accountantAccess: {
      description:
        'Invite your accountant to directly access transactions, receipts and exports.',
      title: 'Dedicated accounting access',
    },
    advancedBillingOptions: {
      description: 'Logo on quotes and invoices, product and service catalogs.',
      title: 'Advanced billing options',
    },
    cardLimit: {
      description: 'Over a rolling 30-day period. Up to {amount} over 7 days.',
      title: 'Card payment limit of {amount}',
    },
    cashDeposits: {
      descriptionFixed: 'Then {amount} excl. tax per additional deposit.',
      descriptionVariable:
        'Then {percentage}% excl. tax of the amount for every additional deposits.',
      titlePlural:
        '{nbOfCashDepositsPerMonth} cash deposits of {maxAmountPerCashDeposit}',
      titleSingular: '1 cash deposit of {maxAmountPerCashDeposit}',
    },
    cashflowManagementDashboard: {
      description:
        'Cashflow management dashboard, dedicated access for your accountant.',
      title: 'Daily management',
    },
    catchPhrase: {
      [ProviderPlanId.Business]: 'Our plan most suited to growing teams',
      [ProviderPlanId.Plus]:
        'The comprehensive plan for developing your business with peace of mind',
      [ProviderPlanId.Pro]:
        'The plan that goes further to support the growth of your business',
    },
    checkDeposit: {
      description: 'Then {amount} excl. tax per additional check.',
      titlePlural: '{nbOfCheckDeposits} check deposits',
      titleSingular: '1 check deposit',
    },
    choosePlan: 'Choose Shine {planName}',
    currentPlan: 'Your current plan',
    customerSupport: {
      description: 'Even faster responses to all your queries.',
      title: 'Priority support, 7 days a week',
    },
    featuresFromPreviousPlan: 'All Shine {planName}',
    insurancesAssistance: {
      description: {
        insurances2024: {
          business:
            'Hospitalization, Transport delay, Screen breakage, Payment card fraud, Legal assistance and debt collection, Purchase protection, Health expenses abroad, Phishing, Rental vehicle and laptop.',
          plus: 'Hospitalization, Transport delay, Screen breakage, Payment card fraud, Legal assistance and debt collection.',
          pro: 'Hospitalization, Transport delay, Screen breakage, Payment card fraud, Legal assistance and debt collection, Purchase protection, Health expenses abroad and Phishing.',
        },
      },
      title: 'Exclusive insurances',
    },
    invoicingTool: {
      description:
        'Unlimited creation of quotes and invoices, customer catalog, customizable mentions, automatic reminders.',
      title: 'Invoicing tool',
    },
    multipleAccounts: {
      description: 'Multiple accounts to better organize your treasury.',
      titlePlural: `1 account + {secondaryBankAccountsCount} wallets`,
      titleSingular: `1 account + {secondaryBankAccountsCount} wallet`,
    },
    phoneSupport: {
      description: 'A Shine advisor available when needed.',
      title: 'Telephone support',
    },
    physicalCards: {
      basic: '{nbOfCards} Mastercard Basic Debit',
      business: '{nbOfCards} Mastercard Business World Debit',
      description: 'Physical cards for you and your team.',
    },
    planName: 'Shine {planName}',
    preAccounting: {
      description:
        'Easy addition of receipts, exports and accounting integrations.',
      title: 'Pre-accounting',
    },
    priceDetails: '/\u00A0month excl. tax',
    sepaOperations: {
      description: 'Then {amount} excl. tax per additional transaction.',
      title: '{nbOfSepaOperations} SEPA transfers and direct debits per month',
    },
    teamExpenses: {
      description:
        'Dedicated access and cards, simplified validations, individual limits.',
      title: 'Team expenses',
    },
    virtualCards: {
      description:
        'Permanent or temporary cards to secure your online purchases.',
      limited: '{nbOfCards} virtual cards',
      unlimited: 'Unlimited virtual cards',
    },
  },
  fr: {
    accountantAccess: {
      description:
        'Invitez votre expert-comptable à accéder directement aux transactions, justificatifs et exports.',
      title: 'Accès comptable dédié',
    },
    advancedBillingOptions: {
      description:
        'Logo sur devis et factures, catalogue de produits et services.',
      title: 'Options de facturation avancées',
    },
    cardLimit: {
      description:
        'Sur 30 jours glissants. Dans la limite de {amount} sur 7 jours.',
      title: 'Plafond de paiement par carte de {amount}',
    },
    cashDeposits: {
      descriptionFixed: 'Puis {amount} HT par dépôt supplémentaire.',
      descriptionVariable:
        'Puis {percentage}\u00A0% HT du montant pour tout dépôt supplémentaire.',
      titlePlural:
        '{nbOfCashDepositsPerMonth} dépôts d’espèces de {maxAmountPerCashDeposit}',
      titleSingular: '1 dépôt d’espèces de {maxAmountPerCashDeposit}',
    },
    cashflowManagementDashboard: {
      description: 'Tableau de bord, accès dédié pour votre comptable.',
      title: 'Gestion du quotidien',
    },
    catchPhrase: {
      [ProviderPlanId.Business]:
        'Notre offre la plus adaptée aux équipes en croissance',
      [ProviderPlanId.Plus]:
        'L’offre complète pour développer votre activité en toute sérénité',
      [ProviderPlanId.Pro]:
        'L’offre qui va plus loin pour accompagner la croissance de votre entreprise',
    },
    checkDeposit: {
      description: 'Puis {amount} HT par chèque supplémentaire.',
      titlePlural: 'Dépôt de {nbOfCheckDeposits} chèques',
      titleSingular: 'Dépôt d’un chèque',
    },
    choosePlan: 'Choisir Shine {planName}',
    currentPlan: 'Votre offre actuelle',
    customerSupport: {
      description: 'Des réponses encore plus rapides à toutes vos demandes.',
      title: 'Support prioritaire 7j/7',
    },
    featuresFromPreviousPlan: 'Tout Shine {planName}',
    insurancesAssistance: {
      description: {
        insurances2024: {
          business:
            'Hospitalisation, Retard de transport, Casse écran, Fraude aux moyens de paiements, Assistance juridique et recouvrement de créances, Protection des achats, Frais de santé à l’étranger, Hameçonnage, Véhicule de location et ordinateur portable.',
          plus: 'Hospitalisation, Retard de transport, Casse écran, Fraude aux moyens de paiements, Assistance juridique et recouvrement de créances.',
          pro: 'Hospitalisation, Retard de transport, Casse écran, Fraude aux moyens de paiements, Assistance juridique et recouvrement de créances, Protection des achats, Frais de santé à l’étranger et Hameçonnage.',
        },
      },
      title: 'Assurances exclusives',
    },
    invoicingTool: {
      description:
        'Création illimitée de devis et factures, catalogue de clients, mentions personnalisables, relances automatiques.',
      title: 'Outil de facturation',
    },
    multipleAccounts: {
      description: 'Plusieurs comptes pour mieux organiser votre trésorerie.',
      titlePlural: `1 compte + {secondaryBankAccountsCount} sous-comptes`,
      titleSingular: `1 compte + {secondaryBankAccountsCount} sous-compte`,
    },
    phoneSupport: {
      description: 'Un·e conseiller·e Shine disponible en cas de besoin.',
      title: 'Rappel téléphonique',
    },
    physicalCards: {
      basic: '{nbOfCards} Mastercard Basic Debit',
      business: '{nbOfCards} Mastercard Business World Debit',
      description: 'Des cartes physiques pour vous et votre équipe.',
    },
    planName: 'Shine {planName}',
    preAccounting: {
      description: 'Ajout facile de reçus, exports et intégrations comptables.',
      title: 'Pré-comptabilité',
    },
    priceDetails: '/\u00A0mois HT',
    sepaOperations: {
      description: 'Puis {amount} HT par mouvement supplémentaire.',
      title: '{nbOfSepaOperations} virements et prélèvements SEPA par mois',
    },
    teamExpenses: {
      description:
        'Accès et cartes dédiées, validations simplifiées, plafonds individuels.',
      title: 'Dépenses en équipe',
    },
    virtualCards: {
      description:
        'Permanentes ou éphémères, pour sécuriser vos achats en ligne.',
      limited: '{nbOfCards} cartes virtuelles',
      unlimited: 'Cartes virtuelles illimitées',
    },
  },
} as const);

export default locales;
