import { ProviderPlanId } from '__generated__/GQL';

import basicCard from '../assets/basicCard.png';
import doubleCard from '../assets/doubleCard.png';
import plusCard from '../assets/plusCard.png';
import tripleCard from '../assets/tripleCard.png';

export const PLANS_CARD_IMAGES: Record<ProviderPlanId, string> = {
  /**
   * 2025 plans
   */
  [ProviderPlanId.FreeMonthly_2025]: basicCard,
  [ProviderPlanId.StartMonthly_2025]: basicCard,
  [ProviderPlanId.StartYearly_2025]: basicCard,
  [ProviderPlanId.PlusMonthly_2025]: basicCard,
  [ProviderPlanId.PlusYearly_2025]: basicCard,
  [ProviderPlanId.BusinessMonthly_2025]: basicCard,
  [ProviderPlanId.BusinessYearly_2025]: basicCard,
  /**
   * 2023 plans
   */
  [ProviderPlanId.Basic]: basicCard,
  [ProviderPlanId.Plus]: plusCard,
  [ProviderPlanId.Pro]: doubleCard,
  [ProviderPlanId.Business]: tripleCard,
  /**
   * Discontinued plans - for pleasing TS
   */
  [ProviderPlanId.MoralPersonBasic]: basicCard,
  [ProviderPlanId.MoralPersonBusiness]: tripleCard,
  [ProviderPlanId.MoralPersonPremium]: plusCard,
  [ProviderPlanId.PhysicalPersonBasic]: basicCard,
  [ProviderPlanId.PhysicalPersonPremium]: plusCard,
};
