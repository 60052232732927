import { type FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  FormattedDate,
  FormattedMessage,
  FormattedNumber,
  IntlProvider,
  useIntl,
} from 'react-intl';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Image, Text, VStack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Callout, Typography } from '@shinetools/sunshine-universal';
import { fromUnixTime } from 'date-fns';
import { z } from 'zod';

import { type ProviderPlanId } from '__generated__/GQL';
import wallet from 'assets/brand/wallet.png';
import useCompanyContext from 'common/hooks/useCompanyContext';
import Button from 'components/_core/Button';
import SunshineCard from 'components/_core/SunshineCard';
import Loader from 'components/Loader';
import { SubscriptionManagementLayout } from 'features/SubscriptionManagement/components/SubscriptionManagementLayout';
import * as subscriptionManagementRoutes from 'features/SubscriptionManagement/routes';
import { plans } from 'features/SubscriptionManagement/routes';
import { toMajorUnits, VAT_RATE } from 'helpers/amount';

import { SubscriptionManagementOverviewDocument } from '../Overview/overview.gql';
import { PLANS_METADATA } from '../Plans/plansMetadata';
import { ChangePlanDocument } from './changePlan.gql';
import { InsuranceTermsForm } from './components/InsuranceTermsForm';
import { PlanRecap } from './components/PlanRecap';
import { SubscriptionManagementPlansDetailDocument } from './planDetails.gql';

export const PlanDetails: FC = () => {
  const companyContext = useCompanyContext();
  const params = useParams<{ providerPlanId: ProviderPlanId }>();
  const history = useHistory();
  const intl = useIntl();

  const requiresInsuranceTermsAgreement =
    !!PLANS_METADATA[params.providerPlanId]?.requiresInsuranceTermsAgreement;

  const form = useForm({
    defaultValues: {
      hasAgreedToInsuranceTerms: false,
    },
    resolver: zodResolver(
      z.object({
        hasAgreedToInsuranceTerms: requiresInsuranceTermsAgreement
          ? z.literal(true, {
              errorMap: () => ({
                message: intl.formatMessage({
                  id: 'subscription.plan_details.insurance_terms.error',
                }),
              }),
            })
          : z.boolean(),
      }),
    ),
  });

  const query = useQuery(SubscriptionManagementPlansDetailDocument, {
    onError() {
      history.replace(plans);
    },
    variables: {
      companyProfileId: companyContext.companyProfileId!,
      providerPlanId: params.providerPlanId,
    },
  });

  const [changePlan] = useMutation(ChangePlanDocument, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: SubscriptionManagementOverviewDocument,
        variables: {
          companyProfileId: companyContext.companyProfileId!,
          includePlanChangeData: true,
        },
      },
    ],
  });

  if (query.loading || !query.data) {
    return <Loader />;
  }

  const {
    targetPricingPlan,
    viewer: { company },
  } = query.data;

  if (!company.subscriptionPlan) {
    throw new Error('No subscription plan found');
  }
  if (!company.bankAccount) {
    throw new Error('No bank account found');
  }

  const { upgradePlanInfo } = company.subscriptionPlan;

  const hasInsufficiendFunds = upgradePlanInfo
    ? toMajorUnits(company.bankAccount.nextBalance) <
      (upgradePlanInfo.upgradeCost + upgradePlanInfo.addonUpgradeCost) *
        VAT_RATE
    : false;

  const targetPlanMetadata = PLANS_METADATA[targetPricingPlan.providerPlanId];

  return (
    <IntlProvider
      {...intl}
      defaultRichTextElements={{
        ...intl.defaultRichTextElements,
        subtitle: (chunks) => (
          <Text as="span" fontWeight="weight-400" size="sm" variant="secondary">
            {chunks}
          </Text>
        ),
      }}
    >
      <SubscriptionManagementLayout
        asideContent={<PlanRecap targetPricingPlan={targetPricingPlan} />}
        prevRoute={subscriptionManagementRoutes.plans}
      >
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit(() => {
              const previousPlan = company.currentPlan.providerPlanId;

              return changePlan({
                variables: {
                  input: {
                    companyProfileId: companyContext.companyProfileId!,
                    providerPlanId: targetPricingPlan.providerPlanId,
                  },
                },
              }).then(() => {
                history.push(
                  `${subscriptionManagementRoutes.root}?${new URLSearchParams({
                    'switched-from-plan': previousPlan,
                  })}`,
                );
              });
            })}
          >
            <VStack align="stretch" spacing="space-40">
              <Box>
                <Typography.Header size="large">
                  <FormattedMessage
                    id="subscription.plan_details.title"
                    values={{
                      planName: targetPricingPlan.brandName,
                    }}
                  />
                </Typography.Header>
              </Box>
              {hasInsufficiendFunds ? (
                <Callout variant="error" withIcon={false}>
                  <Callout.Content>
                    <FormattedMessage
                      id="subscription.plan_details.insufficient_funds_callout"
                      values={{
                        planName: targetPricingPlan.brandName,
                      }}
                    />
                  </Callout.Content>
                </Callout>
              ) : null}
              {upgradePlanInfo ? null : (
                <Callout withIcon={false}>
                  <Callout.Content>
                    <Typography.Text size="small" variant="secondary">
                      <FormattedMessage
                        id="subscription.plan_details.downgrade_info"
                        values={{
                          newPlanName: targetPricingPlan.brandName,
                          newPlanStartDate: (
                            <FormattedDate
                              day="numeric"
                              month="short"
                              value={company.subscriptionPlan.nextBillingAt!}
                              year="numeric"
                            />
                          ),
                          previousPlanName: company.currentPlan.brandName,
                        }}
                      />
                    </Typography.Text>
                  </Callout.Content>
                </Callout>
              )}
              <VStack align="stretch" spacing="space-16">
                <Typography.Text bold>
                  <FormattedMessage id="subscription.plan_details.payment_method.title" />
                </Typography.Text>

                <SunshineCard
                  alignItems="center"
                  backgroundColor="grey.100"
                  border="1px solid"
                  borderColor="grey.800"
                >
                  <SunshineCard.Slot name="aside">
                    <Image
                      backgroundColor="grey.200"
                      borderRadius="radius-8"
                      boxSize="space-32"
                      src={wallet}
                    />
                  </SunshineCard.Slot>

                  <SunshineCard.Content>
                    <Typography.Text bold>
                      <FormattedMessage id="subscription.plan_details.payment_method.main_account" />
                    </Typography.Text>
                  </SunshineCard.Content>
                </SunshineCard>

                <Box>
                  <Typography.Text size="small" variant="secondary">
                    {upgradePlanInfo ? (
                      <FormattedMessage
                        id="subscription.plan_details.upgrade.description_and_price"
                        values={{
                          immediateInvoicedAmount:
                            (upgradePlanInfo.addonUpgradeCost ?? 0) +
                            (upgradePlanInfo.upgradeCost ?? 0),
                          newPlanName: targetPricingPlan.brandName,
                          newPlanPrice: (
                            <FormattedNumber
                              currency="EUR"
                              minimumFractionDigits={0}
                              style="currency"
                              value={targetPricingPlan.priceWithoutVAT / 100}
                            />
                          ),
                          newPlanStartPayingDate: (
                            <FormattedDate
                              day="numeric"
                              month="short"
                              value={fromUnixTime(
                                Number(upgradePlanInfo.nextBillingDate),
                              )}
                              year="numeric"
                            />
                          ),
                          title: (content) => (
                            <Box display="inline-block" paddingBottom="space-8">
                              <Typography.Text
                                bold
                                size="regular"
                                variant="primary"
                              >
                                {content}
                              </Typography.Text>
                            </Box>
                          ),
                        }}
                      />
                    ) : (
                      <FormattedMessage
                        id="subscription.plan_details.downgrade.description"
                        values={{
                          newPlanPrice: (
                            <FormattedNumber
                              currency="EUR"
                              style="currency"
                              value={targetPricingPlan.priceWithoutVAT / 100}
                            />
                          ),
                          newPlanStartPayingDate: (
                            <FormattedDate
                              day="numeric"
                              month="short"
                              value={
                                new Date(
                                  company.subscriptionPlan.nextBillingAt!,
                                )
                              }
                              year="numeric"
                            />
                          ),
                        }}
                      />
                    )}
                  </Typography.Text>
                </Box>
              </VStack>
              {targetPlanMetadata.requiresInsuranceTermsAgreement ? (
                <InsuranceTermsForm brandName={targetPricingPlan.brandName} />
              ) : null}

              <Typography.Text size="small" variant="secondary">
                <FormattedMessage id="subscription.plan_details.footer" />
              </Typography.Text>

              <Button
                alignSelf="flex-start"
                isLoading={form.formState.isSubmitting}
                type="submit"
              >
                <FormattedMessage
                  id="subscription.plan_details.change_plan_cta"
                  values={{
                    planName: targetPricingPlan.brandName,
                  }}
                />
              </Button>
            </VStack>
          </form>
        </FormProvider>
      </SubscriptionManagementLayout>
    </IntlProvider>
  );
};
